import React, { useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/styles';
import AppContext from '../context';

const tempFacts = [{
    "food_name": "Golden Sandwich Cookies",
    "brand_name": "Oreo",
    "serving_qty": 3,
    "serving_unit": "cookies",
    "serving_weight_grams": 34,
    "nf_calories": 170,
    "nf_total_fat": 7,
    "nf_saturated_fat": 2,
    "nf_cholesterol": 0,
    "nf_sodium": 120,
    "nf_total_carbohydrate": 25,
    "nf_dietary_fiber": 0,
    "nf_sugars": 12,
    "nf_protein": 1,
    "nf_potassium": 15,
    "nf_p": null,
    "full_nutrients": [{
        "attr_id": 203,
        "value": 1
      },
      {
        "attr_id": 204,
        "value": 7
      },
      {
        "attr_id": 205,
        "value": 25
      },
      {
        "attr_id": 208,
        "value": 170
      },
      {
        "attr_id": 269,
        "value": 12
      },
      {
        "attr_id": 291,
        "value": 0
      },
      {
        "attr_id": 301,
        "value": 0
      },
      {
        "attr_id": 303,
        "value": 1.08
      },
      {
        "attr_id": 306,
        "value": 15
      },
      {
        "attr_id": 307,
        "value": 120
      },
      {
        "attr_id": 318,
        "value": 0
      },
      {
        "attr_id": 401,
        "value": 0
      },
      {
        "attr_id": 601,
        "value": 0
      },
      {
        "attr_id": 605,
        "value": 0
      },
      {
        "attr_id": 606,
        "value": 2
      }
    ],
    "nix_brand_name": "Oreo",
    "nix_brand_id": "51db37ba176fe9790a898c9a",
    "nix_item_name": "Golden Sandwich Cookies",
    "nix_item_id": "51d2fe0ecc9bff111580ee8b",
    "metadata": {},
    "source": 8,
    "ndb_no": null,
    "tags": null,
    "alt_measures": null,
    "lat": null,
    "lng": null,
    "photo": {
      "thumb": "https://d1r9wva3zcpswd.cloudfront.net/5cc94d52441d7fb21d4ec304.jpeg",
      "highres": null,
      "is_user_uploaded": false
    },
    "note": null,
    "updated_at": "2019-05-01T07:40:04+00:00",
    "nf_ingredient_statement": "Unbleached Enriched Flour (Wheat Flour, Niacin, Reduced Iron, Thiamine Mononitrate (Vitamin B1), Riboflavin (Vitamin B2), Folic Acid), Sugar, High Oleic Canola and/or Palm and/or Canola Oil, Dextrose, High Fructose Corn Syrup, Salt, Cornstarch, Wheat Starch, Baking Soda, Soy Lecithin, Natural and Artificial Flavor."
  },
  {
    "food_name": "Joe-Joe's Chocolate Vanilla Creme Cookies",
    "brand_name": "Trader Joe's",
    "serving_qty": 2,
    "serving_unit": "cookies",
    "serving_weight_grams": 26,
    "nf_calories": 130,
    "nf_total_fat": 6,
    "nf_saturated_fat": 2.5,
    "nf_cholesterol": 0,
    "nf_sodium": 65,
    "nf_total_carbohydrate": 18,
    "nf_dietary_fiber": 0.5,
    "nf_sugars": 11,
    "nf_protein": 1,
    "nf_potassium": null,
    "nf_p": null,
    "full_nutrients": [{
        "attr_id": 203,
        "value": 1
      },
      {
        "attr_id": 204,
        "value": 6
      },
      {
        "attr_id": 205,
        "value": 18
      },
      {
        "attr_id": 208,
        "value": 130
      },
      {
        "attr_id": 269,
        "value": 11
      },
      {
        "attr_id": 291,
        "value": 0.5
      },
      {
        "attr_id": 301,
        "value": 0
      },
      {
        "attr_id": 303,
        "value": 0.72
      },
      {
        "attr_id": 307,
        "value": 65
      },
      {
        "attr_id": 318,
        "value": 0
      },
      {
        "attr_id": 401,
        "value": 0
      },
      {
        "attr_id": 601,
        "value": 0
      },
      {
        "attr_id": 605,
        "value": 0
      },
      {
        "attr_id": 606,
        "value": 2.5
      }
    ],
    "nix_brand_name": "Trader Joe's",
    "nix_brand_id": "51db381b176fe9790a89b587",
    "nix_item_name": "Joe-Joe's Chocolate Vanilla Creme Cookies",
    "nix_item_id": "525ec3a99f1e7c0500000418",
    "metadata": {},
    "source": 8,
    "ndb_no": null,
    "tags": null,
    "alt_measures": null,
    "lat": null,
    "lng": null,
    "photo": {
      "thumb": "https://d1r9wva3zcpswd.cloudfront.net/536a634fa0ec3c690b543024.jpeg",
      "highres": null,
      "is_user_uploaded": false
    },
    "note": null,
    "updated_at": "2018-12-28T06:23:12+00:00",
    "nf_ingredient_statement": "'CANE SUGAR, GRAIN BLEND (CORN FLOUR, SOY FLOUR, RICE FLOUR, CORNSTARCH, POTATO FLOUR, TAPIOCA STARCH, POTATO STARCH, XANTHAN GUM, ARABIC GUM, CARRAGEENAN), PALM OIL, COCOA POWDER (PROCESSED WITH ALKALI), EXPELLER PRESSED CANOLA OIL, INVERT SYRUP, COCOA POWDER, EGG WHITE POWDER, SEA SALT, BAKING SODA, VANILLA BEAN SEEDS, SOY LECITHIN (AN EMULSIFIER), NATURAL FLAVORS, AMMONIUM BICARBONATE.'"
  }
];

const rows = {
  calories: 'Calories',
  fat: 'Total Fat',
  saturatedFat: 'Saturated Fat',
  transFat: 'Trans Fat',
  cholesterol: 'Cholesterol',
  sodium: 'Sodium',
  carbohydrates: 'Total Carbohydrate',
  fiber: 'Dietary Fiber',
  sugars: 'Sugars',
  protein: 'Protein',
  calcium: 'Calcium',
  iron: 'Iron',
  potassium: 'Potassium',
};

const goodnessMap = {
  calories: false,
  fat: false,
  saturatedFat: false,
  transFat: false,
  cholesterol: false,
  sodium: false,
  carbohydrates: false,
  fiber: true,
  sugars: false,
  protein: true,
  calcium: true,
  iron: true,
  potassium: true,
};

const useStyles = makeStyles({
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
    margin: '5%',
    width: '90%',
    '& td, & th': {
      border: '3px solid #333',
      padding: '1vw',
      textAlign: 'center',
      width: '33%',
      position: 'relative',
    },
    '& th': {
      fontWeight: 'bold',
      textAlign: 'left',
    },
    '& tr:first-child th:first-child': {
      border: 'none',
      color: 'transparent',
    },
    '& img': {
      width: '90%',
    },
  },
  fab: {
    position: 'fixed',
    margin: 10,
    top: 0,
    bottom: 'auto',
    left: 0,
    right: 'auto',
    zIndex: 1,
  },
  diff: {
    position: 'absolute',
    right: '0.5em',
  },
  good: {
    color: '#2ecc40',
  },
  bad: {
    color: '#ff4136',
  },
});

export default function Compare() {
  let { facts } = useContext(AppContext);
  const classes = useStyles();

  if (!facts.length) {
    // facts = tempFacts;
    return <Redirect to="/scan" />;
  }

  const other = index => facts[index ? 0 : 1];
  const presentable = (product, prop) => Math.round(Number(product.labelNutrients[prop].value));

  const format = (curProd, index, prop) => {
    const otherProd = other(index);

    const curValue = presentable(curProd, prop);
    const otherValue = presentable(otherProd, prop);

    const diff = curValue - otherValue;

    if (!diff) {
      return curValue;
    }

    const good = diff > 0 === goodnessMap[prop] ? 'good' : 'bad';

    return (
      <>
        {curValue}
        <span className={`${classes.diff} ${classes[good]}`}>
          {diff < 0 ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowUpIcon />
          )}
          {Math.abs(diff)}
        </span>
      </>
    );
  }

  return (
    <>
      <Fab component={Link} to="/scan" aria-label="back" className={classes.fab}>
        <ArrowBackIcon />
      </Fab>
      <table className={classes.table}>
        <tbody>
          <tr>
            <th></th>
            {facts.map(product => (
              <Typography component="td" variant="h6" key={product.fdcId}>
                {product.description}
              </Typography>
            ))}
          </tr>
          {Object.entries(rows).map(([rowKey, rowName]) => (
            <tr key={rowKey}>
              <Typography component="th" variant="h6">
                {rowName}
              </Typography>
              {facts.map((product, i) => (
                <Typography component="td" key={product.fdcId}>
                  {format(product, i, rowKey)}
                </Typography>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
