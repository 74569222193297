const apiRequest = (path, { method = 'POST', ...params }) => fetch(`${process.env.NUTRITION_API_BASE_URL}/${path}?api_key=${process.env.NUTRITION_API_APP_KEY}`, {
  headers: {
    'Content-Type': 'application/json',
  },
  method,
  ...(Object.keys(params).length ? { body: JSON.stringify(params) } : {}),
});

export const fromId = (fdcId) => apiRequest(fdcId, { method: 'GET' })
  .then(res => res.json());

export const fromUPC = (upc) => apiRequest('search', {
  generalSearchInput: `gtinUpc:${upc}`,
  includeDataTypes: {
    Branded: true,
  },
})
  .then(res => res.json())
  .then(res => fromId(res.foods[0].fdcId));


export const search = (generalSearchInput) => apiRequest('search', {
  generalSearchInput,
  includeDataTypes: {
    Branded: true,
  },
})
  .then(res => res.json());
