import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppContext from './context';
import routes from './routes';

export default function App() {
  const [facts, setFacts] = useState([]);

  return (
    <AppContext.Provider value={{ facts, setFacts }}>
      <Router>
        <Switch>
          {routes.map(config => <Route {...config} />)}
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}
