import React from 'react';
import { Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import CropFreeIcon from '@material-ui/icons/CropFree';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    borderTop: '2px solid #333',
    '&:first-child': {
      borderTop: 'none',
    },
  },
});

export default function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonBase component={Link} to="/scan" className={classes.button}>
        <CropFreeIcon />
        <Typography>
          Scan
        </Typography>
      </ButtonBase>
      <ButtonBase component={Link} to="/search" className={classes.button}>
        <KeyboardIcon />
        <Typography>
          Search
        </Typography>
      </ButtonBase>
    </div>
  );
}
