import React, { useState, useEffect, useMemo } from 'react';
import Downshift from 'downshift';
import debounce from 'debounce-fn';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { search as apiSearch } from '../utils/nutritionalFacts';

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').toString().indexOf(suggestion.fdcId) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.fdcId}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.brandOwner}
      {suggestion.brandOwner ? ': ' : ''}
      {suggestion.description}
    </MenuItem>
  );
}

function Suggestions({ children, search }) {
  const [results, setResults] = useState([]);
  const apiDebounced = useMemo(() => debounce((s) => {
    apiSearch(s).then((res) => {
      setResults(res.foods.slice(0, 9));
    });
  }), []);
  const inputValue = search.trim().toLowerCase();

  useEffect(() => {
    apiDebounced(inputValue);
  }, [inputValue]);

  return children({ results });
}

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    padding: 10,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
}));

export default function Manual() {
  const classes = useStyles();

  return (
    <Downshift>
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
      }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps({
          placeholder: 'Search for a product',
        });

        return (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              label: 'Product',
              InputLabelProps: getLabelProps({ shrink: true }),
              InputProps: { onBlur, onFocus },
              inputProps,
            })}

            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  <Suggestions search={inputValue}>
                    {({ results }) => results.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.fdcId }),
                        highlightedIndex,
                        selectedItem,
                      })
                    )}
                  </Suggestions>
                </Paper>
              ) : null}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
}
