import BarcodeReader from './pages/BarcodeReader';
import Compare from './pages/Compare';
import Home from './pages/Home';
import Search from './pages/Search';

export default [
  {
    key: 'Home',
    path: '/',
    component: Home,
    exact: true,
  },
  {
    key: 'BarcodeReader',
    path: '/scan',
    component: BarcodeReader,
    exact: true,
  },
  {
    key: 'Search',
    path: '/search',
    component: Search,
    exact: true,
  },
  {
    key: 'Compare',
    path: '/compare',
    component: Compare,
    exact: true,
  },
];
