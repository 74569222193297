import React, { useRef, useEffect, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library';
import { fromUPC as nutritionalFacts } from '../utils/nutritionalFacts';
import AppContext from '../context';

const useStyles = makeStyles({
  fab: {
    position: 'fixed',
    margin: '10px',
    top: 0,
    bottom: 'auto',
    left: 0,
    right: 'auto',
    zIndex: 1,
  },
});

export default function BarcodeReader() {
  const { facts, setFacts } = useContext(AppContext);
  const classes = useStyles();
  const previewNode = useRef();

  useEffect(() => {
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.UPC_A, BarcodeFormat.UPC_E]);

    const codeReader = new BrowserMultiFormatReader(hints, 1000);

    async function getFacts(deviceId, barcodes) {
      let { text } = await codeReader.decodeFromInputVideoDevice(deviceId, previewNode.current);
      text = text.replace(/^00/, '0');

      if (barcodes[text]) {
        return getFacts(deviceId, barcodes);
      }

      const newBarcodes = { ...barcodes, [text]: nutritionalFacts(text) };

      if (Object.keys(newBarcodes).length < 2) {
        return getFacts(deviceId, newBarcodes);
      }

      codeReader.stopStreams();
      return newBarcodes;
    }

    (async () => {
      try {
        const videoInputDevices = await codeReader.listVideoInputDevices();

        const { deviceId } = (
          videoInputDevices.find(device => (/back|rear/i).test(device.label)) ||
          videoInputDevices[0]
        );

        const requests = await getFacts(deviceId, {});
        const facts = await Promise.all(Object.values(requests));

        setFacts(facts);
      } catch (err) {
        console.warn(err);
      }
    })();

    return () => {
      codeReader.stopStreams();
    };
  }, []);

  if (facts.length) {
    return <Redirect to="/compare" />;
  }

  return (
    <>
      <Fab component={Link} to="/" aria-label="back" className={classes.fab}>
        <ArrowBackIcon />
      </Fab>
      <video
        ref={previewNode}
        style={{ width: '100%', height: '100%' }}
      />
    </>
  );
}
